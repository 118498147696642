import * as React from "react";
import Header from "../components/Header";
import Footer from "../components/Footer";
import { Helmet } from "react-helmet";

const termsOfuse = () => {
  return (
    <main>
      <Helmet>
        <meta charSet="utf-8" />
        <title>United Green Energy Credits | Terms of use</title>

        <meta
          name="description"
          content="United Green Energy can provide green energy to customers in a multitude of ways from many renewable energy services and products to offsetting credits. Check out our services pages to learn more about how to go green with UGE! "
        />
        <link
          rel="icon"
          href="https://imgix.cosmicjs.com/5ffde510-a9f1-11ec-bd4d-d755cb3d1844-UGE-Icon-Green-01.png"
        />
      </Helmet>
      <Header />
      <div class="Terms px-4 md:px-0">
        {" "}
        <div class="hero items-center pb-10">
          <div class="mx-auto max-w-7xl">
            <div class="flex justify-center items-center">
              <div class="md:w-1/2">
                {" "}
                <h1 class="mt-4 text-4xl tracking-tight font-extrabold text-black sm:mt-5 sm:text-6xl lg:mt-6 xl:text-6xl">
                  Terms of Use
                </h1>
                <p class="mt-3 text-base text-gray-600 sm:mt-8 sm:text-xl lg:text-lg xl:text-xl">
                  By purchasing any offsets from United Green Energy Credits,
                  the customer agrees to be bound by the terms and conditions as
                  set forth on this page, effective May 1, 2019.
                </p>{" "}
                <div class="button-group border-t pt-8 mt-8 ">
                  <div class="rounded-md ">
                    <a
                      href="/terms-of-use/general-terms"
                      class="flex items-center justify-center px-10 py-5 border border-transparent text-base font-medium rounded-md text-white bg-rose-500 hover:bg-rose-600 md:py-4 md:text-lg md:px-10"
                    >
                      General Terms and Conditions
                    </a>
                  </div>

                  <div class="mt-4 rounded-md ">
                    <a
                      href="/terms-of-use/privacy-policy"
                      class="flex items-center justify-center px-10 py-5 border border-transparent text-base font-medium rounded-md text-white bg-rose-500 hover:bg-rose-600  md:py-4 md:text-lg md:px-10"
                    >
                      Privacy Policy
                    </a>
                  </div>
                  <div class="mt-4 rounded-md ">
                    <a
                      href="/terms-of-use/ccpa"
                      class="flex items-center justify-center px-10 py-5 border border-transparent text-base font-medium rounded-md text-white bg-rose-500 hover:bg-rose-600  md:py-4 md:text-lg md:px-10"
                    >
                      CCPA
                    </a>
                  </div>
                </div>
              </div>
              <div class="hidden md:block pl-10 w-1/2  relative">
                <img
                  class="mx-auto rounded-lg"
                  src="https://imgix.cosmicjs.com/4a031ce0-a9dc-11ec-bd4d-d755cb3d1844-terms-hero.png"
                  alt=""
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </main>
  );
};

export default termsOfuse;
